import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from '@stripe/stripe-js';
import firebase from '../Utils/firebase'

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import { STRIPE_KEY } from "../Utils/Config"
import { BRAND_COLORS, features } from '../Utils/Constants';
import { EVENT, logEvent } from '../Utils/Utils';

import CustomizedSnackbar from "../Components/Snackbar";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.up("xs")]: {
      minHeight: "56px",
    },
  },
  flex: {
    flexGrow: 1,
    },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Subscribe = () => {
    const classes = useStyles();
    const [snackbar, setSnackbar] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    let selectedPrice = null;

    document.body.style = "background: " + BRAND_COLORS.WHITE;

    useEffect(() => {
        var db = firebase.firestore();
            async function getProducts() {
            
            db.collection('products')
            .where('active', '==', true)
            .get()
            .then(function (querySnapshot) {
                return querySnapshot.forEach(async function (doc) {
                    let product = { doc: doc, prices: [] };

                    const priceSnap = await doc.ref.collection('prices').where('active', '==', true).get();
                    priceSnap.docs.forEach((price) => {
                        product.prices.push(price);
                    });
                    setProducts(oldArray => [...oldArray, product]);
                    
                });
            });
        }
        getProducts();
    }, []);
    
    const signOut = () => {
        firebase.auth().signOut().then(function () {
            // Sign-out successful.
        }).catch(function (error) {
            logEvent(EVENT.CATCH, "signOut", error, "Subscribe.js")
        });
    }

    const handleSubscribe = async (price) => {
        if (!price) {return;}
        setLoading(true);
        setSnackbar({
          text: "Redirecting you to payment",
          severity: "success",
        });
        var db = firebase.firestore();
        var currentUser = firebase.auth().currentUser;

        const docRef = await db
        .collection('users')
        .doc(currentUser.uid)
        .collection('checkout_sessions')
        .add({
                price: price.id,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                allow_promotion_codes: true,
            });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {
                // We have a session, let's redirect to Checkout
                // Init Stripe
                const stripe = await loadStripe(STRIPE_KEY);
                stripe.redirectToCheckout({ sessionId });
            }
        });
         
    }

    return (
        <>
            {snackbar && (
                <CustomizedSnackbar
                callback={() => {
                    setSnackbar(null);
                }}
                {...snackbar}
                />
            )}
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" color="secondary">
                <Toolbar className={classes.toolbar} style={{ display: "flex" }}>
                <div style={{ display: "flex", flex: 1, marginRight: "auto", justifyContent: "flex-start" }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Menu"
                    onClick={signOut}>
                    <ArrowBackIcon />
                </IconButton> 
                </div>
                
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={process.env.PUBLIC_URL + '/IconWhiteTransparent.png'} alt="Lotus Book Folding" height={56} width={56}></img>
                </div>
                    
                <div style={{ display: "flex", flex: 1, marginRight: "auto", justifyContent: "flex-start" }}></div>
                
                </Toolbar>
            </AppBar>
            <Toolbar className={classes.toolbar} />

            <Container>
                <Grid container direction="column" alignItems="center">

                    <Grid container alignItems="center" style={{ justifyContent: "space-evenly", marginTop: "32px", marginBottom: "16px" }}>
                        {products.length > 0 ? 
                        products.map((prod => {
                            return (<>
                                {prod.prices.map((pr) => {
                                    const price = pr.data();
                                    if (price.interval === "month") {
                                        return (
                                            <Paper onClick={()=>{handleSubscribe(pr)}} style={{cursor: "pointer", padding: "16px", backgroundColor: BRAND_COLORS.White, borderStyle: "solid", borderColor: BRAND_COLORS.PRIMARY, minWidth: "160px"}}>
                                                <Grid item container direction="column" alignItems="center" spacing={5}>
                                                    <Grid item><Typography variant="button" style={{color: BRAND_COLORS.PRIMARY, fontWeight: "bold"}}>MONTHLY</Typography></Grid>
                                                    <Grid item><Typography variant="h5" style={{color: BRAND_COLORS.PRIMARY, fontWeight: "bold"}}>$14.99/mo</Typography></Grid>
                                                    <Grid item><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY}}>Billed monthly</Typography></Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    }
                                    if (price.interval === "year") {
                                        selectedPrice = pr;
                                        return (
                                            <Paper onClick={()=>{handleSubscribe(pr)}} style={{cursor: "pointer", padding: "16px", backgroundColor: BRAND_COLORS.White, borderStyle: "solid", borderColor: BRAND_COLORS.SUCCESS, minWidth: "160px", borderWidth: "4px"}}>
                                                <Grid item container direction="column" alignItems="center" spacing={5}>
                                                    <Grid item><Typography variant="button" style={{color: BRAND_COLORS.SUCCESS, fontWeight: "bold"}}>ANNUAL</Typography></Grid>
                                                    <Grid item style={{padding: "0px"}}><Typography variant="h5" style={{ color: BRAND_COLORS.SUCCESS, fontWeight: "bold"}}>$12.49/mo</Typography></Grid>
                                                    <Grid item style={{padding: "0px"}}><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY, textDecoration: "line-through"}}>$14.99/mo</Typography></Grid>
                                                    <Grid item><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY}}>Billed annually at $149.99</Typography></Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    }
                                    return null;
                                })}
                            </>);
                        })) : 
                        <>
                            <Paper onClick={()=>{}} style={{cursor: "pointer", padding: "16px", backgroundColor: BRAND_COLORS.White, borderStyle: "solid", borderColor: BRAND_COLORS.SUCCESS, minWidth: "160px", borderWidth: "4px"}}>
                                <Grid item container direction="column" alignItems="center" spacing={5}>
                                    <Grid item><Typography variant="button" style={{color: BRAND_COLORS.SUCCESS, fontWeight: "bold"}}>ANNUAL</Typography></Grid>
                                    <Grid item style={{padding: "0px"}}><Typography variant="h5" style={{ color: BRAND_COLORS.SUCCESS, fontWeight: "bold"}}>$12.49/mo</Typography></Grid>
                                    <Grid item style={{padding: "0px"}}><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY, textDecoration: "line-through"}}>$14.99/mo</Typography></Grid>
                                    <Grid item><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY}}>Billed annually at $149.99</Typography></Grid>
                                </Grid>
                            </Paper>
                            <Paper onClick={()=>{}} style={{cursor: "pointer", padding: "16px", backgroundColor: BRAND_COLORS.White, borderStyle: "solid", borderColor: BRAND_COLORS.PRIMARY, minWidth: "160px"}}>
                                <Grid item container direction="column" alignItems="center" spacing={5}>
                                    <Grid item><Typography variant="button" style={{color: BRAND_COLORS.PRIMARY, fontWeight: "bold"}}>MONTHLY</Typography></Grid>
                                    <Grid item><Typography variant="h5" style={{color: BRAND_COLORS.PRIMARY, fontWeight: "bold"}}>$14.99/mo</Typography></Grid>
                                    <Grid item><Typography variant="subtitle2" style={{color: BRAND_COLORS.SECONDARY}}>Billed monthly</Typography></Grid>
                                </Grid>
                            </Paper>
                        </>}
                        
                    </Grid>
                    <Grid container spacing={2} style={{justifyContent: "space-evenly", alignItems: "center"}}>
                        <Grid item>
                            <List dense>
                                {features.map((feature) => {
                                    return (
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckCircleIcon style={{color: BRAND_COLORS.SUCCESS}} />
                                            </ListItemIcon>
                                            <ListItemText primary={feature}/>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>
                        <Grid item>
                            <img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" style={{maxWidth: "325px", maxHeight: "325px"}} />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Button onClick={()=>{handleSubscribe(selectedPrice)}} size="large" style={{ backgroundColor: BRAND_COLORS.SUCCESS, color: BRAND_COLORS.WHITE, paddingLeft: "16px", paddingRight: "16px" }}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                                <div style={{fontWeight: "bold", lineHeight: 1.25}}>
                                    Subscribe
                                </div>
                                <div>
                                    <Typography variant="subtitle2" style={{fontSize: "x-small"}}>$149.99 per year</Typography>
                                </div>
                            </div>
                        </Button>
                    </Grid>
                        
                    <Grid item style={{ flexGrow: 1, marginBottom: "16px"}}>
                        <Tooltip title="Your credit card will be charged after the free trial period ends. The monthly subscription is $14.99 and automatically renews each month. The annual subscription, charged as one upfront payment, is $149.99 and automatically renews each year.">
                            <Typography variant="subtitle2" style={{fontSize: "x-small"}}>Recurring billing. Cancel anytime.</Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
 
export default Subscribe;