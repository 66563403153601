import React, { useEffect, useState } from 'react';
import PDFDocument from "@react-pdf/pdfkit";
import blobStream from "blob-stream";
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { PATTERNVIEW_TYPES, LOGO_BLACK } from "../Utils/Constants";
import { getHeightCM } from "../Utils/Utils";

function mmToPoints(mm) {
    //72 points per inch
    return mm * 720 / 254;
}

function PDFGenerator(props) {
    const [url, setURL] = useState("");
    const leftMargin = 36;
    const rightMargin = 576;
    const topMargin = 36;
    const bottomMargin = 756;
    let verticalSpace = bottomMargin - topMargin;
    const horizontalSpace = rightMargin - leftMargin;
    //const pageWidth = 612;
    const colors = { black: "black", white: "white", grey: "grey" };
    const { pattern, viewType, name, foldType, lastOddPage, rawImageUrl } = props;

    useEffect(() => { 
        if (url) {
            window.open(url);
        }
    }, [url]);

    const generatePDF = () => {
        if (!pattern) {
            return;
        }

        if (viewType === PATTERNVIEW_TYPES.IMAGE) {
            const doc = new PDFDocument();
            const stream = doc.pipe(blobStream());
            stream.on('finish', () => {
                const url = stream.toBlobURL('application/pdf')
                setURL(url);
            });

            if (mmToPoints(pattern.height) > verticalSpace) {
                doc.text("This pattern is too long to print", leftMargin, topMargin);
                doc.end();
            }

            //Front page
            doc.image(rawImageUrl, 156, 200, { fit: [300, 300], align: 'center', valign: 'center' });
            doc.image(LOGO_BLACK, 36, 726, { width: 50 });
            doc.text(`Name: ${name}`, 210, topMargin + 20);
            doc.text(`Last odd Page: ${lastOddPage}`, 210, topMargin + 40);
            doc.text(`Height: ${getHeightCM(pattern.height)}cm`, 210, topMargin + 60);
            doc.text(`Method: ${foldType}`, 210, topMargin + 80);
            doc.addPage();

            //Set font size
            doc.fontSize(10);

            const pageLength = 20;
            let xPosition = leftMargin;
            for (let x = 0; x < pattern.Pages.length; x++) {
                //Each page
                const thisPage = pattern.Pages[x];
                let lineTop = [xPosition, topMargin];
                let lineBottom = [xPosition, mmToPoints(pattern.height) + topMargin];
                doc.polygon(lineTop, lineBottom).stroke(colors.black);

                for (let y = 0; y < thisPage.Folds.length; y++) {
                    //Each fold
                    const thisFold = pattern.Pages[x].Folds[y];
                    const color = thisFold.depth > 0 ? colors.white : colors.grey;

                    const start = mmToPoints(thisFold.start) + topMargin;
                    const end = mmToPoints(thisFold.end) + topMargin;
                    const TL = [xPosition, start];
                    const TR = [xPosition + pageLength, start];
                    const BL = [xPosition, end];
                    const BR = [xPosition + pageLength, end];

                    doc.polygon(TL, BL, BR, TR).fillOpacity(0.5).fill(color);
                }

                //Add top page numbers
                doc.save();
                doc.rotate(-90, { origin: [xPosition, topMargin] });
                doc.fillColor(colors.black).text((thisPage.number).toString(), xPosition + 2, topMargin + 5, {stroke: true, characterSpacing: 1});
                doc.restore();

                //Add bottom page numbers
                doc.save();
                doc.rotate(-90, { origin: [xPosition, mmToPoints(pattern.height) + topMargin] });
                doc.fillColor(colors.black).text((thisPage.number).toString(), xPosition - 20, mmToPoints(pattern.height) + topMargin + 7, {stroke: true, characterSpacing: 1});
                doc.restore();

                //Add footer
                doc.image(LOGO_BLACK, 36, 726, { width: 50 });

                //27 pages fit on each PDF page
                //If we hit 27(0 index), create new page.
                if (x !== 0 && x % 26 === 0) {
                    //Add line to end of page
                    xPosition += pageLength;
                    lineTop = [xPosition, topMargin];
                    lineBottom = [xPosition, mmToPoints(pattern.height) + topMargin];
                    doc.polygon(lineTop, lineBottom).stroke(colors.black);

                    //Add line across the top
                    const lineTopStart = [leftMargin, topMargin];
                    const lineTopEnd = [xPosition, topMargin];
                    doc.polygon(lineTopStart, lineTopEnd).stroke();

                    //Add line across the bottom
                    const lineBottomStart = [leftMargin, mmToPoints(pattern.height) + topMargin];
                    const lineBottomEnd = [xPosition, mmToPoints(pattern.height) + topMargin];
                    doc.polygon(lineBottomStart, lineBottomEnd).stroke();

                    //Reset to start of page
                    doc.addPage();
                    xPosition = leftMargin;
                } else {
                    xPosition += pageLength;
                }

                //Add line at the end of the pattern
                if (x === pattern.Pages.length - 1) {
                    lineTop = [xPosition, topMargin];
                    lineBottom = [xPosition, mmToPoints(pattern.height) + topMargin];
                    doc.polygon(lineTop, lineBottom).stroke();

                    //Add line across the top
                    const lineTopStart = [leftMargin, topMargin];
                    const lineTopEnd = [xPosition, topMargin];
                    doc.polygon(lineTopStart, lineTopEnd).stroke();
                    
                    //Add line across the bottom
                    const lineBottomStart = [leftMargin, mmToPoints(pattern.height) + topMargin];
                    const lineBottomEnd = [xPosition, mmToPoints(pattern.height) + topMargin];
                    doc.polygon(lineBottomStart, lineBottomEnd).stroke();
                }
            }
            doc.end();
        } else if (viewType === PATTERNVIEW_TYPES.GRID) {
            //Decreasing vertical space to show Lotus Logo at the bottom
            verticalSpace = bottomMargin - topMargin - 36;
            const doc = new PDFDocument();
            const stream = doc.pipe(blobStream());
            stream.on('finish', () => {
                const url = stream.toBlobURL('application/pdf')
                setURL(url);
            });

            const foldLength = 45;
            const foldWidth = 30;
            const textOffset = 10;

            doc.image(rawImageUrl, 156, 200, { fit: [300, 300], align: 'center', valign: 'center' });
            doc.image(LOGO_BLACK, 36, 681, { width: 75 });
            doc.text(`Name: ${name}`, 210, topMargin + 20);
            doc.text(`Last odd Page: ${lastOddPage}`, 210, topMargin + 40);
            doc.text(`Height: ${getHeightCM(pattern.height)}cm`, 210, topMargin + 60);
            doc.text(`Method: ${foldType}`, 210, topMargin + 80);
            doc.addPage();

            //Add footer
            doc.image(LOGO_BLACK, 36, 681, { width: 75 });

            //Reset font size
            doc.fontSize(12);

            let xPosition = leftMargin;
            let yPosition = topMargin;

            for (let p = 0; p < pattern.Pages.length; p++) {
                //Each pattern page
                const thisPage = pattern.Pages[p];

                //Each page we want a line that spans the page width
                let leftPoint = [leftMargin, yPosition];
                let rightPoint = [rightMargin, yPosition];
                doc.polygon(leftPoint, rightPoint).stroke(colors.black);

                //Write page number
                doc.text("p." + (thisPage.number).toString(), xPosition + textOffset, yPosition + textOffset);
                xPosition += foldLength;
                
                let measurement = 0;
                for (let f = 0; f < thisPage.Folds.length; f++) {
                    //Each fold
                    const thisFold = thisPage.Folds[f];

                    //Don't write last fold info, just reset x and y position
                    if (f === thisPage.Folds.length - 1) {
                        xPosition = leftMargin;
                        yPosition += foldWidth;
                        //if we've run out of vertical space, reset y position and start new page
                        if (yPosition > verticalSpace - foldWidth) {
                            yPosition = topMargin;
                            doc.addPage();
                            //Add footer
                            doc.image(LOGO_BLACK, 36, 681, { width: 75 });
                        }
                        continue;
                    }

                    //Write fold information
                    measurement += thisFold.length;
                    doc.text(getHeightCM(measurement).toString(), xPosition + textOffset, yPosition + textOffset);
                    doc.rect(xPosition, yPosition, foldLength, foldWidth).stroke();
                    xPosition += foldLength;

                    //if we've run out of horizontal space and there are still more folds for this page, reset x position and increase y position
                    //minus 2 because we technically don't write the last fold
                    if (xPosition > horizontalSpace - foldLength && f !== thisPage.Folds.length - 2) {
                        xPosition = leftMargin + foldLength;
                        yPosition += foldWidth;
                        //if we've run out of vertical space, reset y position and start new page
                        if (yPosition > verticalSpace - foldWidth) {
                            yPosition = topMargin;
                            doc.addPage();
                            //Add footer
                            doc.image(LOGO_BLACK, 36, 681, { width: 75 });
                        }
                    }
                }
            }

            doc.end();
        }
    }
    
    return (
        <>
            <IconButton aria-label="download" onClick={() => { generatePDF();}}>
                <GetAppIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
        </>);
}
export default PDFGenerator;